<template>
  <main class="auth signup">
    <div class="signup__background"></div>
    <section class="signup__form">
      <div v-if="signupData.step !== 1" class="signup__form-controls">
        <span
          @click="goBackToStep(signupData.step - 1)"
          class="signup__form-go-back"
        >
          <i class="icon-arrow-left"></i>
          {{ $languages[getAppLanguage].signUp.goBack }}
        </span>
        <span class="signup__form-clear" @click="startOver()">
          {{ $languages[getAppLanguage].signUp.startOver }}
        </span>
      </div>
      <div class="signup__loader"></div>

      <div v-if="validationErrors.length" class="signup__errors">
        <div>
          <div
            v-for="(error, errorIndex) in validationErrors"
            :key="`error-${errorIndex}`"
            v-html="error"
          ></div>
        </div>
      </div>

      <div v-if="signupData.step === 1" class="signup__form-step">
        <img src="../assets/images/logo.svg" :alt="`${$companyName} logo`" />
        <h2 class="signup__form-title">
          {{ $languages[getAppLanguage].signUp.step1.title }}
        </h2>
        <p class="signup__form-sub-title">
          {{ $languages[getAppLanguage].signUp.step1.sub }}
        </p>

        <form>
          <label for="firstName">
            <span>
              {{ $languages[getAppLanguage].signUp.step1.firstNameLabel }}
            </span>
            <input
              type="text"
              v-model="signupData.firstName"
              id="firstName"
              :placeholder="
                $languages[getAppLanguage].signUp.step1.firstNamePlaceholder
              "
              @input="saveSignupData"
              autofocus
            />
          </label>

          <label for="lastName">
            <span>
              {{ $languages[getAppLanguage].signUp.step1.lastNameLabel }}
            </span>
            <input
              type="text"
              v-model="signupData.lastName"
              id="lastName"
              :placeholder="
                $languages[getAppLanguage].signUp.step1.lastNamePlaceholder
              "
              @input="saveSignupData"
            />
          </label>

          <label for="email">
            <span>
              {{ $languages[getAppLanguage].signUp.step1.emailLabel }}
            </span>
            <input
              type="email"
              v-model="signupData.email"
              id="email"
              :placeholder="
                $languages[getAppLanguage].signUp.step1.emailPlaceholder
              "
              @input="saveSignupData"
            />
          </label>

          <div class="signup__form-checkbox-label">
            <Checkbox
              @checkbox-check="acceptTerms"
              :checked="signupData.terms"
              alignTop
            >
              <span>
                {{
                  $languages[getAppLanguage].signUp.step1.termsAgreementMessage
                }}
              </span>
            </Checkbox>
          </div>

          <Button @button-clicked="validateStep1" icon="translate-main" submit>
            {{ $languages[getAppLanguage].signUp.step1.ctaText }}
          </Button>

          <hr />
          <router-link to="/login">
            <span @click="clearSignupData()">
              {{ $languages[getAppLanguage].signUp.step1.signUpLink }}
            </span>
          </router-link>

          <hr />
          <div>
            <p v-html="$languages[getAppLanguage].signUp.step1.termsLink"></p>
            <p v-html="$languages[getAppLanguage].signUp.step1.privacyLink"></p>
          </div>

          <br />
          <br />
          <br />
          <br />
          <br />
        </form>
      </div>

      <!-- <div v-if="signupData.step === 2" class="signup__form-step">
        <h2 class="signup__form-title">
          {{ signupData.firstName }}, how will you use {{ $companyName }}?
        </h2>
        <p class="signup__form-sub-title">
          Knowing how you will use {{ $companyName }} will help us create the
          best experience for you.
        </p>
        <section class="signup__form-selections-wrapper">
          <section class="signup__form-selections">
            <div
              v-for="plan in plans"
              :key="plan.id"
              class="signup__form-selection signup__form-selection--plan"
            >
              <div
                @click="selectPlan(plan)"
                :class="[
                  'signup__form-selection-box',
                  {
                    'signup__form-selection-box--active':
                      signupData.plan === plan.id
                  }
                ]"
              >
                <img :src="findImage(plan.image)" :alt="plan.image" />

                <div class="signup__form-selection-box-features">
                  <h3>
                    {{ plan.id }}
                  </h3>
                </div>
              </div>
            </div>
          </section>
          <Button @button-clicked="validateStep2">
            I will use it {{ signupData.plan }}
          </Button>

          <br />
          <br />
          <br />
          <br />
          <br />
        </section>
      </div> -->

      <div
        v-if="signupData.step === 2"
        class="signup__form-step signup__form-step--2"
      >
        <h2>
          {{ signupData.firstName }},
          {{ $languages[getAppLanguage].signUp.step2.title }}
        </h2>
        <p>
          {{ $languages[getAppLanguage].signUp.step2.sub }}
        </p>
        <br />
        <form>
          <label for="passwrod">
            <span>
              {{ $languages[getAppLanguage].signUp.step2.passwordLabel }}
            </span>
            <input
              type="password"
              v-model="signupData.password"
              id="passwrod"
              :placeholder="
                $languages[getAppLanguage].signUp.step2.passwordPlaceholder
              "
              @input="saveSignupData"
              autocomplete="true"
            />
          </label>

          <label for="repeatPasswrod">
            <span>{{
              $languages[getAppLanguage].signUp.step2.passwordRepeatLabel
            }}</span>
            <input
              type="password"
              v-model="signupData.confirmPassword"
              id="confirmPasswrod"
              :placeholder="
                $languages[getAppLanguage].signUp.step2
                  .passwordRepeatPlaceholder
              "
              @input="saveSignupData"
              autocomplete="off"
            />
          </label>

          <Button @button-clicked="validateStep2" submit>
            {{ $languages[getAppLanguage].signUp.step2.ctaText }}
          </Button>
          <br />
          <br />
        </form>
      </div>

      <div v-if="signupData.step === 3" class="signup__form-step">
        <section>
          <h2 class="signup__form-title">
            {{
              getLoadingCustomerSubscriptionDashboard
                ? $languages[getAppLanguage].signUp.step3.loadingTitle
                : $languages[getAppLanguage].signUp.step3.title
            }}
          </h2>

          <p class="signup__form-sub-title">
            {{
              getLoadingCustomerSubscriptionDashboard
                ? $languages[getAppLanguage].signUp.step3
                    .loadingPaymentsProviderMessageTop
                : $languages[getAppLanguage].signUp.step3.sub
            }}
          </p>

          <section
            v-if="getLoadingCustomerSubscriptionDashboard"
            class="signup__form-loading-stripe"
          >
            <Spinner spinnerSize="60px" />
            <p>
              {{
                $languages[getAppLanguage].signUp.step3
                  .loadingPaymentsProviderMessageBottom
              }}
            </p>
          </section>

          <form
            v-if="!getLoadingCustomerSubscriptionDashboard"
            class="signup__form-selections-wrapper"
          >
            <section class="signup__form-selections">
              <div
                v-for="(price, priceIndex) in prices"
                :key="price.id"
                class="signup__form-selection signup__form-selection--price"
              >
                <div
                  @click="selectMembership(price)"
                  :class="[
                    'signup__form-selection-box',
                    {
                      'signup__form-selection-box--active':
                        signupData.price === price.data.unit_amount,
                      'signup__form-selection-box--first': priceIndex === 0,
                      'signup__form-selection-box--last':
                        priceIndex === prices.length - 1
                    }
                  ]"
                >
                  <span
                    class="signup__form-selection-box-label"
                    v-if="price.data.metadata.deal"
                  >
                    {{ price.data.metadata.deal }}
                  </span>

                  <span>
                    <small>
                      {{ $languages[getAppLanguage].signUp.step3.billed }}
                    </small>
                    <h4>
                      {{ price.data.description }}
                    </h4>
                    <br />
                    <h1>€{{ price.data.unit_amount / 100 }}</h1>
                  </span>

                  <br />
                  <span>
                    <strong>€{{ price.data.metadata.monthly_price }}</strong>
                    / {{ $languages[getAppLanguage].signUp.step3.month }}
                  </span>
                </div>
              </div>
            </section>

            <Button
              @button-clicked="validateStep3"
              submit
              :disabled="!signupData.priceId"
            >
              {{
                getLoadingCustomerSubscriptionDashboard
                  ? $languages[getAppLanguage].signUp.step3.loadingCta
                  : `${$languages[getAppLanguage].signUp.step3.ctaTextLeft} ${signupData.membership} ${$languages[getAppLanguage].signUp.step3.ctaTextRight}`
              }}
            </Button>
            <br />
            <br />
            <br />
            <br />
            <br />
          </form>
        </section>
      </div>
    </section>
  </main>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

// Components
import Button from '@/components/Button'
import Checkbox from '@/components/Checkbox'
import Spinner from '@/components/Spinner'

export default {
  name: 'SignUp',

  data: () => ({
    validationErrors: [],

    localStorageId: 'signup-data',
    initialPriceId: null,

    signupData: {
      firstName: null,
      lastName: null,
      email: null,
      terms: false,
      price: 7200,
      priceId: 'price_1Jk7HIFEXN7g4j2DHnUrB6TC',
      membership: 'yearly',
      password: null,
      confirmPassword: null,
      step: 1
      // plan: 'on my own'
    },

    // plans: [
    //   {
    //     id: 'on my own',
    //     image: 'illustration-personal.jpg',
    //     features: ['1 user']
    //   },
    //   {
    //     id: 'with my team',
    //     image: 'illustration-teams.jpg',
    //     features: ['2+ users']
    //   }
    // ],

    prices: [],
    promoCodes: []
  }),

  components: {
    Button,
    Checkbox,
    Spinner
  },

  watch: {
    getStripePrices() {
      this.prices = this.getStripePrices
      this.saveSignupData()

      return this.getStripePrices
    },

    getIsUserSubscribed() {
      return this.getIsUserSubscribed
    }
  },

  computed: {
    ...mapGetters([
      'getAppLanguage',
      'getStripePrices',
      'getLoadingCustomerSubscriptionDashboard',
      'getIsUserSubscribed'
    ])
  },

  beforeMount() {
    this.setStripePrices()
    this.saveSignupData()
  },

  mounted() {
    const previouslyStoredSignupData = localStorage.getItem(this.localStorageId)

    if (this.$route.query.lang) this.setAppLanguage(this.$route.query.lang)

    this.prices = this.getStripePrices
    this.saveSignupData()

    if (this.$user.uid && this.getIsUserSubscribed)
      this.$router.replace('/document/loading...', () => {})

    if (previouslyStoredSignupData)
      this.signupData = JSON.parse(previouslyStoredSignupData)
  },

  methods: {
    ...mapActions([
      'signUpAction',
      'setStripePrices',
      'setAppLanguage',
      'setLoadingCustomerSubscriptionDashboard'
    ]),

    /**
     *
     */
    findImage(image) {
      return require(`../assets/images/${
        this.$getDarkMode() ? 'dark-' : ''
      }${image}`)
    },

    /**
     *
     */
    saveSignupData() {
      this.setInitialPriceId()

      return localStorage.setItem(
        this.localStorageId,
        JSON.stringify(this.signupData)
      )
    },

    /**
     *
     */
    setInitialPriceId() {
      if (!this.initialPriceId)
        this.prices.map(price => {
          if (price.data.description === 'Yearly')
            this.initialPriceId = price.id

          if (!this.signupData.priceId)
            this.signupData.priceId = this.initialPriceId // Requierd for when user does not click on price
        })
    },

    /**
     *
     */
    clearSignupData() {
      localStorage.removeItem(this.localStorageId)
    },

    /**
     *
     */
    startOver() {
      this.clearSignupData()
      this.resetValidationErrors()
      this.signupData = {
        firstName: null,
        lastName: null,
        email: null,
        terms: false,
        price: 7200,
        priceId: 'price_1Jk7HIFEXN7g4j2DHnUrB6TC',
        membership: 'yearly',
        password: null,
        confirmPassword: null,
        step: 1,
        plan: 'on my own'
      }
    },

    /**
     *
     */
    acceptTerms(checkboxState) {
      this.signupData.terms = checkboxState
      this.signupData.privacy = checkboxState

      this.saveSignupData()
    },

    /**
     *
     */
    goBackToStep(step = 1) {
      this.saveSignupData()
      this.resetValidationErrors()
      return (this.signupData.step = step)
    },

    /**
     *
     */
    resetValidationErrors() {
      return (this.validationErrors = [])
    },

    /**
     *
     */
    validateStep1() {
      // Clear the errors before we validate again
      this.resetValidationErrors()

      // email validation
      if (!this.signupData.firstName) {
        this.validationErrors.push(
          this.$languages[this.getAppLanguage].signUp.step1.errors.firstName
        )
      }

      // email validation
      if (!this.signupData.lastName) {
        this.validationErrors.push(
          this.$languages[this.getAppLanguage].signUp.step1.errors.lastName
        )
      }

      // email validation
      if (!this.signupData.email) {
        this.validationErrors.push(
          this.$languages[this.getAppLanguage].signUp.step1.errors.email.empty
        )
      } else {
        if (/.+@.+/.test(this.signupData.email) !== true) {
          this.validationErrors.push(
            this.$languages[this.getAppLanguage].signUp.step1.errors.email
              .invalid
          )
        }
      }

      if (!this.signupData.terms) {
        this.validationErrors.push(
          this.$languages[this.getAppLanguage].signUp.step1.errors.terms
        )
      }

      // when valid then sign in
      if (!this.validationErrors.length) {
        this.saveSignupData()
        this.signupData.step = 2
      }
    },

    // /**
    //  *
    //  */
    // validateStep2() {
    //   this.saveSignupData()
    //   this.signupData.step = 3
    // },

    // /**
    //  *
    //  */
    // selectPlan(plan) {
    //   this.signupData.plan = plan.id

    //   this.saveSignupData()
    // },

    /**
     *
     */
    selectMembership(price) {
      this.signupData.priceId = price.id
      this.signupData.price = price.data.unit_amount
      this.signupData.membership = price.data.description.toLowerCase()

      this.saveSignupData()
    },

    /**
     *
     */
    validateStep2() {
      // Clear the errors before we validate again
      this.resetValidationErrors()

      if (!this.signupData.priceId)
        this.signupData.priceId = this.initialPriceId // Requierd for when user does not click on price

      // password validation
      if (!this.signupData.password) {
        this.validationErrors.push(
          this.$languages[this.getAppLanguage].signUp.step2.errors.password
            .empty
        )
      } else {
        if (/.{8,}/.test(this.signupData.password) !== true) {
          this.validationErrors.push(
            this.$languages[this.getAppLanguage].signUp.step2.errors.password
              .invalid
          )
        }
      }

      if (this.signupData.password !== this.signupData.confirmPassword) {
        this.validationErrors.push(
          this.$languages[this.getAppLanguage].signUp.step2.errors.password
            .unmatched
        )
      }

      // when valid then sign in
      if (!this.validationErrors.length) {
        this.saveSignupData()
        this.signupData.step = 3
      }
    },

    /**
     *
     */
    validateStep3() {
      this.saveSignupData()
      this.signUp()
    },

    /**
     *
     */
    getPaymentIntent(event) {
      this.signupData.initialPaymentIntentId = event.paymentIntent.id
      this.saveSignupData()
      return this.signUp()
    },

    /**
     *
     */
    async signUp() {
      const displayName = `${this.signupData.firstName} ${this.signupData.lastName}`
      const displayLetters = displayName
        .match(/\b(\w)/g)
        .join('')
        .toUpperCase()

      this.setLoadingCustomerSubscriptionDashboard(true)

      return await this.signUpAction({
        firstName: this.signupData.firstName,
        lastName: this.signupData.lastName,
        displayName: displayName,
        displayLetters: displayLetters,
        email: this.signupData.email,
        password: this.signupData.password,
        terms: this.signupData.terms,
        privacy: this.signupData.privacy,
        // plan: this.signupData.plan,
        membership: this.signupData.membership,
        firstDocumentCreated: false,
        priceId: this.signupData.priceId
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.signup__loader {
  position: absolute;
  top: 0;
  width: 100%;
  height: var(--size-unit-half);
  background: linear-gradient(
    90deg,
    var(--color-main-dark) 0%,
    var(--color-main-light) 100%
  );
  transition: background var(--transition-default);
}

.signup__background {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(
      var(--color-brand-darkest-rgb),
      var(--opacity-overlay)
    );
  }

  @include screen-small {
    background-image: url('../assets/auth-background-blurred.jpg');
    background-size: cover;
  }
}

.signup__errors {
  width: 100%;
  max-width: 440px;
  padding-bottom: var(--size-unit-3);
  background-color: var(--color-theme-lightest);
  color: var(--color-error);
  z-index: 9999;
}

.signup__errors-reset {
  color: var(--color-main);
  text-decoration: underline;
  cursor: pointer;
}

.signup__form {
  @include form;
}

.signup__form-controls {
  display: flex;
  width: 100%;
  justify-content: center;
  z-index: 999;

  @include screen-medium {
    position: absolute;
    justify-content: flex-start;
    top: 40px;
    left: 64px;
  }
}

.signup__form-loading-stripe {
  display: flex;
  justify-content: space-between;

  img {
    // Needed because there is an overright for other images in .signup__form img
    min-width: 60px !important;
  }

  p {
    margin: 0 0 40px 40px;
  }
}

.signup__form-clear {
  display: inline-block;
  margin-bottom: var(--size-unit-5);
  margin-left: var(--size-unit-4);
  text-decoration: underline;
  color: var(--color-main);
  cursor: pointer;
}

.signup__form-go-back {
  display: inline-block;
  margin-bottom: var(--size-unit-4);
  color: var(--color-main);
  text-decoration: underline;
  cursor: pointer;
}

.signup__form-membership-checkbox,
.signup__form-discount-checkbox {
  margin-bottom: var(--size-unit-5);
}

.signup__form-checkbox-label {
  display: flex;
  margin: var(--size-unit-2) 0 0;

  a {
    color: var(--color-dark);
  }
}

.signup__form-divider {
  margin: var(--size-unit) 0 var(--size-unit-2);
  height: 1px;
  background-color: var(--color-theme-lighter);
}

.signup__form-step {
  @include hide-scrollbar;
  overflow-y: scroll;
  width: 100%;
  max-width: 440px;
  height: 100%;

  img {
    margin-bottom: var(--size-unit);
    width: 50%;
    min-width: 120px;
  }

  @include screen-medium {
    height: initial;

    img {
      margin-bottom: var(--size-unit-5);
    }
  }

  &--2 {
    justify-content: center;
  }
}

.signup__form-sub-title {
  margin-bottom: var(--size-unit-2);
}

::-webkit-scrollbar {
  display: none;
}

.signup__form-selections {
  @include selections;
}

.signup__form-selection {
  @include selection;
}

.signup__form-selection-box {
  @include selection-box;
}

.signup__form-old-price {
  text-decoration: line-through;
}

.signup__stripe-card {
  border: 1px solid var(--color-theme-light);
  border-radius: var(--radius-default);
  padding: var(--size-unit);

  &--ready {
    border-color: var(--color-success);
  }
}
</style>
